import axios from 'axios';
import { get } from 'lodash';
import { parseQueryParams, ROOT_API_DMS, ROOT_API_URL } from '../../constant';
const FETCH_AVAILABLE_VIDEOS = 'fetch_available_videos';
const FETCH_VIDEOS = 'fetch_videos';
const FETCH_LIVE_VIDEO_DURATION = 'fetch_live_video_duration';
const FETCH_LIVE_VIDEO_DURATION_BY_STREAM = 'fetch_live_video_duration_by_stream';
const STOP_LIVE_DVR = 'stop_live_dvr';
const RENEW_LIVE_DVR = 'renew_live_dvr';
const CREATE_LIVE_DVR = 'create_live_dvr';
const CREATE_NEW_VIDEOS = 'create_new_videos';
const REFRESH_VIDEO_URL = 'refresh_video_url';
const FETCH_DVR_VIDEOS = 'FETCH_DVR_VIDEOS';
const FETCH_NEW_LINK = 'fetch_new_link';
const FETCH_DVR_DURATION = 'fetch_dvr_duration';
const FETCH_HEART_BEAT = 'FETCH_HEART_BEAT';
const FETCH_VIDEO_CALL_DRIVER_URL = 'FETCH_VIDEO_CALL_DRIVER_URL';
export const FETCH_DVR_AND_LIVE_VIEW_CAMERA_CONFIG = 'FETCH_DVR_AND_LIVE_VIEW_CAMERA_CONFIG';
export const FETCH_PLAY_BACK = 'FETCH_PLAY_BACK';
const FETCH_ALARM_LINK = 'FETCH_ALARM_LINK';
const FETCH_SNAPSHOT_HISTORICAL = 'FETCH_SNAPSHOT_HISTORICAL';
const FETCH_SNAPSHOT = 'FETCH_SNAPSHOT';
const RETRY_VIDEO = 'RETRY_VIDEO';
const DELETE_DVR_VIDEOS = 'DELETE_DVR_VIDEOS';
const FETCH_ALL_DVR_DURATIONS = 'FETCH_ALL_DVR_DURATIONS';
const RECHARGE_DVR = 'RECHARGE_DVR';
const CancelToken = axios.CancelToken;
let cancel;
export function fetchAvailableVideos(accesstoken, id) {
    // id should either be trip Id or {alarmname}-{timeStamp}-{vehicleNumber}
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_DMS}dvr/${id}`, config);
    return {
        type: FETCH_AVAILABLE_VIDEOS,
        promise: request,
    };
}
export function fetchheartBeat(accesstoken, params) {
    // id should either be trip Id or {alarmname}-{timeStamp}-{vehicleNumber}
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
        // ...params
        }),
    };
    const request = axios.post(`${ROOT_API_DMS}live/dvr/monitor`, params, config);
    return {
        type: FETCH_HEART_BEAT,
        promise: request,
    };
}
export function fetchVideos(accesstoken, from, to, vehicleId, videoName) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            vehicleId,
            videoName,
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}dvr/`, config);
    return {
        type: FETCH_VIDEOS,
        promise: request,
    };
}
export function retryDvrVideo(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.patch(`${ROOT_API_DMS}dvr/retry/${id}`, config);
    return {
        type: RETRY_VIDEO,
        promise: request,
    };
}
export function fetchDvrDuration(accesstoken, from, to, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            deviceId: get(data, 'assetIdCamera'),
            licensePlate: get(data, 'vehicleNumber'),
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}live/dvr/duration`, config);
    return {
        type: FETCH_DVR_DURATION,
        promise: request,
    };
}
export function fetchDvrAndLiveViewConfig(accesstoken, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
        // from,
        // to,
        // deviceId: get(data, 'assetIdCamera'),
        // licensePlate: get(data, 'vehicleNumber'),
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}camera-config/${vehicleId}`, config);
    return {
        type: FETCH_DVR_AND_LIVE_VIEW_CAMERA_CONFIG,
        promise: request,
    };
}
export function fetchLiveVideoDuration(accesstoken, from, to, deviceId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            deviceId,
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}dvr/live/dvr/duration`, config);
    return {
        type: FETCH_LIVE_VIDEO_DURATION,
        promise: request,
    };
}
export function fetchLiveVideoDurationByStream(accesstoken, streamRequestId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            streamRequestId,
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}dvr/live/dvr/stream`, config);
    return {
        type: FETCH_LIVE_VIDEO_DURATION_BY_STREAM,
        promise: request,
    };
}
export function createNewVideo(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accesstoken,
        }),
    };
    const request = axios.post(`${ROOT_API_DMS}dvr`, data, config);
    return {
        type: CREATE_NEW_VIDEOS,
        promise: request,
    };
}
export function refreshVideoURLForHowen(params, accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}alarms/alarm-evidence-refresh`, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function fetchAlramByToken(params, liveShareToken) {
    const config = {
        // headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}alarms/share/${liveShareToken}`, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function refreshVideoURL(accesstoken, dvrId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accesstoken,
        }),
    };
    const request = axios.post(`${ROOT_API_DMS}dvr/refresh/${dvrId}`, null, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function fetchNewVideoLink(accesstoken, token) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}webhook/video/${token} `, config);
    return {
        type: FETCH_NEW_LINK,
        promise: request,
    };
}
export function createNewLiveVideo(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_DMS}live/dvr`, data, config);
    return {
        type: CREATE_LIVE_DVR,
        promise: request,
    };
}
export function stopLiveStream(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_DMS}live/dvr/stop`, data, config);
    return {
        type: CREATE_LIVE_DVR,
        promise: request,
    };
}
export function fetchVideoCallDriverUrl(accesstoken, assetId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            assetId,
        }),
    };
    const request = axios.post(`${ROOT_API_DMS}live/dvr/voice-call-driver`, null, config);
    return {
        type: FETCH_VIDEO_CALL_DRIVER_URL,
        promise: request,
    };
}
export function fetchPlayBack(params, accesstoken) {
    if (cancel) {
        cancel();
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}playback`, config);
    return {
        type: FETCH_PLAY_BACK,
        promise: request,
    };
}
export function fetchSnapshots(param) {
    const cancelCallbackKey = 'FETCH_SNAPSHOT';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const data = {
        ...param,
    };
    const request = axios.post(`${ROOT_API_DMS}snapshot/live`, data);
    return {
        type: FETCH_SNAPSHOT,
        promise: request,
    };
}
export function fetchAlarmLink(accesstoken, params, body) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_URL}alarms/share`, body, config);
    return {
        type: FETCH_ALARM_LINK,
        promise: request,
    };
}
export function fetchSnapshotsHistorical(param) {
    if (cancel) {
        cancel();
    }
    const config = {
        params: parseQueryParams({
            requestParams: param,
        }),
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}snapshot/historical`, config);
    return {
        type: FETCH_SNAPSHOT_HISTORICAL,
        promise: request,
    };
}
export function fetchVideoShareLink(params, body) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_DMS}share/dvr`, body, config);
    return {
        type: FETCH_ALARM_LINK,
        promise: request,
    };
}
export function fetchShareVideoByToken(params, liveShareToken) {
    const config = {
        // headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_DMS}dvr/share/${liveShareToken}`, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function fetchDvrVideos(startDate, endDate, vehicleId) {
    const config = {
        params: parseQueryParams({
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            vehicleId,
        }),
    };
    return axios.get(`${ROOT_API_DMS}dvr`, config);
}
export function fetchVehiclePlaybackSessionByToken(params, body, liveShareToken) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_DMS}playback/share/session/${liveShareToken}`, body, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function fetchVehicleVideoShareByToken(params, liveShareToken) {
    const config = {
        params: {
            ...params,
        },
    };
    const request = axios.get(`${ROOT_API_DMS}live/dvr/share/${liveShareToken}`, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function monitorVehicleVideoShareByToken(liveShareToken, streamSubId) {
    const config = {};
    const request = axios.get(`${ROOT_API_DMS}live/dvr/share/monitor/${liveShareToken}/${streamSubId}`, config);
    return {
        type: FETCH_HEART_BEAT,
        promise: request,
    };
}
export function fetchVehiclePlaybackShareByToken(params, body, liveShareToken) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_DMS}playback/share/${liveShareToken}`, body, config);
    return {
        type: REFRESH_VIDEO_URL,
        promise: request,
    };
}
export function monitorVehiclePlaybackShareByToken(params, liveShareToken) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_DMS}playback/share/monitor/${liveShareToken}`, config);
    return {
        type: FETCH_HEART_BEAT,
        promise: request,
    };
}
export function deleteDvrVideos(dvrIds) {
    const config = {
        params: parseQueryParams({
            dvrIds: dvrIds,
        }),
    };
    const request = axios.patch(`${ROOT_API_DMS}dvr/archive`, {}, config);
    return {
        type: DELETE_DVR_VIDEOS,
        promise: request,
    };
}
export function fetchAllVehicleDvrDurations() {
    const config = {};
    const request = axios.get(`${ROOT_API_DMS}live/dvr/duration/all-vehicle`, config);
    return {
        type: FETCH_ALL_DVR_DURATIONS,
        promise: request,
    };
}
export function rechargeDvr(params) {
    const config = { params: parseQueryParams(params) };
    const request = axios.post(`${ROOT_API_DMS}live/dvr/recharge`, {}, config);
    return {
        type: RECHARGE_DVR,
        promise: request,
    };
}
